import loadable from '@loadable/component';

export const Main = loadable(
  () => import(/* webpackPrefetch: true */ './Main'),
);
export const Root = loadable(
  () => import(/* webpackPrefetch: true */ './Root'),
);
export const Chart = loadable(
  () => import(/* webpackPrefetch: true */ './Chart'),
);
export const Recent = loadable(
  () => import(/* webpackPrefetch: true */ './Recent'),
);

export const Login = loadable(
  () => import(/* webpackPrefetch: true */ './Login'),
);
export const Logout = loadable(
  () => import(/* webpackPrefetch: true */ './Logout'),
);
export const KakaoLogin = loadable(
  () => import(/* webpackPrefetch: true */ './KakaoLogin'),
);
export const Register = loadable(
  () => import(/* webpackPrefetch: true */ './Register'),
);
export const FindPassword = loadable(
  () => import(/* webpackPrefetch: true */ './FindPassword'),
);
export const Password = loadable(
  () => import(/* webpackPrefetch: true */ './Password'),
);
export const Profile = loadable(
  () => import(/* webpackPrefetch: true */ './Profile'),
);
export const AuthError = loadable(
  () => import(/* webpackPrefetch: true */ './AuthError'),
);

export const PolicyService = loadable(
  () => import(/* webpackPrefetch: true */ './PolicyService'),
);
export const PolicyPrivacy = loadable(
  () => import(/* webpackPrefetch: true */ './PolicyPrivacy'),
);

export const Support = loadable(
  () => import(/* webpackPrefetch: true */ './Support'),
);
export const SupportPost = loadable(
  () => import(/* webpackPrefetch: true */ './SupportPost'),
);
export const SupportSubmit = loadable(
  () => import(/* webpackPrefetch: true */ './SupportSubmit'),
);

export const Search = loadable(
  () => import(/* webpackPrefetch: true */ './Search'),
);

export const Membership = loadable(
  () => import(/* webpackPrefetch: true */ './Membership'),
);

export const Notice = loadable(
  () => import(/* webpackPrefetch: true */ './Notice'),
);
export const Setting = loadable(
  () => import(/* webpackPrefetch: true */ './Setting'),
);

export const Playlist = loadable(
  () => import(/* webpackPrefetch: true */ './Playlist'),
);

export const PlaylistInformation = loadable(
  () => import(/* webpackPrefetch: true */ './PlaylistInformation'),
);

export const Notes = loadable(
  () => import(/* webpackPrefetch: true */ './Notes'),
);
