import React from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import auth from '../utils/auth';
import Modal from './Modal';

interface IPrivateRoute extends RouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute = ({ component: Component, ...rest }: IPrivateRoute) => {
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={(props) =>
        auth.getToken() !== null ? (
          <Component {...props} />
        ) : (
          <Modal
            open={true}
            onClose={() => history.goBack()}
            firstButtonLabel="확인"
            onClickFirstButton={() => history.push('/auth/login')}
            title="로그인 필요"
            content={
              <>
                로그인 후 이용 가능한 기능입니다. <br />
                로그인 하시겠습니까?
              </>
            }
          />
        )
      }
    />
  );
};

export default PrivateRoute;
