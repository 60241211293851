import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import MenuItem from './MenuItem';
import { menuList } from './constants';

import Logout from '../../assets/icons/logout.svg';
import Cancel from '../../assets/icons/cancel.svg';
import Edit from '../../assets/icons/edit.svg';

import useDarkMode from '../../utils/useDarkMode';

import Logo from '../../assets/logo.svg';
import DarkLogo from '../../assets/logo-dark.svg';
import { useHistory } from 'react-router';
import auth from '../../utils/auth';
import useHiddenBodyOverflow from '../../utils/useHiddenBodyOverflow';
import { api } from '../../utils/api';

interface ISideMenu {
  isLoggedIn?: boolean;
  onClickClose: () => void;
}

const SideMenu: React.FC<ISideMenu> = ({
  isLoggedIn = false,
  onClickClose,
}) => {
  const history = useHistory();
  const isDarkMode = useDarkMode();
  const [isUploader, setIsUploader] = useState<boolean>(false);
  const renderedLogo = isDarkMode ? <DarkLogo /> : <Logo />;

  useEffect(() => {
    if (!auth.getToken()) {
      return;
    }
    const fetchData = async () => {
      const {
        data: { data },
      } = await api.get('/user');
      console.log(data);
      setIsUploader(data.user.is_uploader ? true : false);
    };
    fetchData();
  }, []);

  useHiddenBodyOverflow();

  return (
    <OverlayContainer>
      <Overlay onClick={onClickClose} />
      <Wrapper>
        <Container>
          <Header>
            <LogoWrapper>{renderedLogo}</LogoWrapper>
            <IconList>
              {auth.getToken() && (
                <LogoutIcon onClick={() => history.push('/auth/logout')}>
                  <Logout />
                </LogoutIcon>
              )}
              <CancelIcon onClick={onClickClose}>
                <Cancel />
              </CancelIcon>
            </IconList>
          </Header>
          <Content>
            <Profile
              style={{ padding: isLoggedIn ? '80px 10px' : '96px 10px' }}
            >
              {isLoggedIn ? (
                <>
                  <EmailText>mustardsauce@naver.com</EmailText>
                  <EditButton>
                    <EditIcon>
                      <Edit />
                    </EditIcon>
                    프로필 수정
                  </EditButton>
                </>
              ) : (
                <>
                  {auth.getToken() ? (
                    <LoginText>
                      {auth.getEmail() || '이메일이 등록되지 않았습니다.'}
                    </LoginText>
                  ) : (
                    <>
                      <LoginText>
                        안녕하세요.
                        <br />
                        코드스코어 입니다.
                      </LoginText>
                      <LoginDescription>
                        회원 서비스 이용을 위해 로그인 해주세요.
                      </LoginDescription>
                      <LoginButton onClick={() => history.push('/auth/login')}>
                        로그인
                      </LoginButton>
                    </>
                  )}
                </>
              )}
            </Profile>
            {auth.getToken() && (
              <MenuList>
                {menuList.map(({ menu, auth, isUploaderOnly, ...props }) => {
                  if (!isLoggedIn && auth) {
                    return null;
                  }
                  if (isUploaderOnly && !isUploader) {
                    return null;
                  }

                  return (
                    <MenuItem
                      key={`menu-${menu}`}
                      menu={menu}
                      isDarkMode={isDarkMode}
                      isUploaderOnly={isUploaderOnly}
                      {...props}
                    />
                  );
                })}
              </MenuList>
            )}
            <VersionText>v1.2.1</VersionText>
          </Content>
        </Container>
      </Wrapper>
    </OverlayContainer>
  );
};

export default SideMenu;

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
`;

const Overlay = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  opacity: 0.83;
  background-color: #111316;
`;

const Wrapper = styled.div`
  z-index: 999;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  @media screen and (max-width: 500px) {
    height: 100%;
    width: 80%;
  }
`;

const Container = styled.div`
  height: 96%;
  width: 500px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;

  html[color-mode='dark'] & {
    background-color: #212632;
  }

  @media screen and (max-width: 960px) {
    width: 430px;
  }

  @media screen and (max-width: 500px) {
    height: 100%;
    width: 100%;
  }
`;

const Header = styled.div`
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(89, 89, 89, 0.15);

  html[color-mode='dark'] & {
    border-bottom: 1px solid #484848;
  }

  @media screen and (max-width: 500px) {
    padding: 22px 26px;
    padding-right: 33px;
  }
`;

const LogoWrapper = styled.div`
  cursor: pointer;

  @media screen and (max-width: 500px) {
    & > svg {
      width: 121px;
      height: 17px;
    }
  }
`;

const IconList = styled.div`
  display: flex;
  align-items: center;

  html[color-mode='dark'] & path {
    fill: white;
  }
`;

const Icon = styled.div`
  cursor: pointer;
`;

const LogoutIcon = styled(Icon)`
  width: 27px;
  height: 27px;
  margin-right: 21px;

  @media screen and (max-width: 500px) {
    width: 18px;
    height: 18px;

    & > svg {
      width: 18px;
      height: 18px;
    }
  }
`;

const CancelIcon = styled(Icon)`
  width: 21px;
  height: 21px;

  @media screen and (max-width: 500px) {
    width: 18px;
    height: 18px;

    & > svg {
      width: 18px !important;
      height: 18px;
    }
  }
`;

const Content = styled.div`
  padding: 40px;
  padding-top: 0;
  height: inherit;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media screen and (max-width: 500px) {
    padding: 30px;
  }
`;

const Profile = styled.div`
  padding-right: 20px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 500px) {
    padding: 50px 0 !important;
    padding-bottom: 40px !important;
  }
`;

const EmailText = styled.span`
  opacity: 0.8;
  font-size: 18px;
  line-height: 1.22;
  color: #1e1e1e;

  html[color-mode='dark'] & {
    color: white;
  }
`;

const EditButton = styled.button`
  border-radius: 2px;
  background-color: rgba(115, 125, 135, 0.58);
  padding: 8px 15px;
  border: none;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.38;
  color: #ffffff;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-top: 19px;
`;

const EditIcon = styled(Icon)`
  width: 14px;
  height: 14px;
  margin-right: 6px;
`;

const MenuList = styled.div`
  padding-top: 31.2px;
  border-top: 1px solid rgba(89, 89, 89, 0.15);

  html[color-mode='dark'] & {
    border-top: 1px solid #484848;
  }

  @media screen and (max-width: 500px) {
    &&& {
      border-top: 0;
    }
  }
`;

const VersionText = styled.span`
  font-size: 13px;
  line-height: 1.69;
  color: rgba(0, 0, 0, 0.3);
  margin-top: auto;
  margin-bottom: 8px;
  margin-left: 10px;

  html[color-mode='dark'] & {
    color: rgba(255, 255, 255, 0.3);
  }

  @media screen and (max-width: 500px) {
    margin-bottom: -7px;
    margin-left: 2px;
  }
`;

const LoginText = styled.span`
  color: #1e1e1e;
  font-size: 26px;
  line-height: 1.31;

  html[color-mode='dark'] & {
    color: white;
  }

  @media screen and (max-width: 500px) {
    font-size: 18px;
    line-height: 1.39;
  }
`;

const LoginDescription = styled.p`
  opacity: 0.6;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.63;
  color: black;
  margin-top: 10px;

  html[color-mode='dark'] & {
    color: white;
  }

  @media screen and (max-width: 500px) {
    font-size: 13px;
    line-height: 1.69;
    margin-top: 5px;
  }
`;

const LoginButton = styled.button`
  border-radius: 3px;
  background-color: rgba(115, 125, 135, 0.58);
  margin-top: 50px;
  width: 100%;
  padding: 15px 0;
  border: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.13;
  color: #ffffff;

  @media screen and (max-width: 500px) {
    margin-top: 30px;
    font-size: 13px;
    line-height: 1.38;
    padding: 8px 0;
  }
`;
