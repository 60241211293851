import styled from '@emotion/styled';
import ServiceWrapper from './ServiceWrapper';
import Button from './Button';

export const StyledServiceWrapper = styled(ServiceWrapper)`
  flex-direction: column;
`;

export const TopContents = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  max-width: 1040px;
  padding: 75px 0 45px 0;
  margin: 0 auto;

  @media screen and (max-width: 1300px) {
    max-width: unset;
    width: 90%;
  }

  @media screen and (max-width: 960px) {
    padding-top: 66px;
    padding-bottom: 21px;
  }

  @media screen and (max-width: 500px) {
    padding-top: 50px;
    padding-bottom: 77px;
  }
`;

export const StyledButton = styled(Button)`
  max-width: 226px;
  padding: 16px 0;

  @media screen and (max-width: 960px) {
    max-width: 200px;
    padding: 10px 0;
  }

  @media screen and (max-width: 500px) {
    max-width: 124px;
    padding: 8.1px 0;
  }
`;

export const BottomContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;

  html[color-mode='dark'] & {
    background-color: rgba(17, 19, 22, 0.6);
  }
`;

export const BottomContents = styled.div`
  width: 100%;
  max-width: 1040px;
  padding: 50px 0 200px 0;
  margin: 0 auto;

  @media screen and (max-width: 1300px) {
    max-width: unset;
    width: 90%;
    padding-bottom: 100px;
  }

  @media screen and (max-width: 500px) {
    padding: 0 0 40px 0;
  }
`;
