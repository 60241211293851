import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Link, useHistory, NavLink, useParams } from 'react-router-dom';

import SideMenu from './SideMenu';

import Logo from '../assets/logo.svg';
import AlertIcon from '../assets/alert-icon.svg';
import SidebarIcon from '../assets/sidebar-icon.svg';

import auth from '../utils/auth';

import {
  SearchBox,
  SearchBoxIcon,
  SearchBoxIconWrap,
  SearchBoxInput,
} from '../components/Search';
import MobileSearchHeader from './MobileSearchHeader';
import useHiddenBodyOverflow from '../utils/useHiddenBodyOverflow';
import css from '@emotion/css';

interface IHeader {
  position: string;
}

const Header = ({ position }: IHeader) => {
  const history = useHistory();
  const params = useParams<{ searchParams: string }>();
  const [searchValue, setSearchValue] = useState('');
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const onClickOpenSideMenu = () => setIsSideMenuOpen(true);
  const onClickCloseSideMenu = () => setIsSideMenuOpen(false);

  useEffect(() => {
    if (params.searchParams) {
      setSearchValue(params.searchParams);
    }
  }, [params.searchParams]);

  useHiddenBodyOverflow(isSearchOpen);

  return (
    <>
      <Wrapper position={position}>
        <Container>
          <LeftWrap>
            <LogoLink to="/">
              <Logo />
            </LogoLink>
            <MenuWrap>
              <Menu type="playlist" to="/playlist" />
              <Menu type="chart" to="/chart" />
            </MenuWrap>
            <SearchBox>
              <SearchBoxIconWrap>
                <SearchBoxIcon />
              </SearchBoxIconWrap>
              <SearchBoxInput
                placeholder="검색어를 입력하세요."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    history.push(`/search/${searchValue}`);
                    window.location.reload();
                  }
                }}
              />
            </SearchBox>
          </LeftWrap>
          <RightWrap>
            <SearchIconWrap onClick={() => setIsSearchOpen(true)}>
              <SearchBoxIcon />
            </SearchIconWrap>
            <Alert to="/notice" count={auth.getNoticeCount()} />
            <SidebarIconWrap onClick={onClickOpenSideMenu}>
              <SidebarIcon />
            </SidebarIconWrap>
          </RightWrap>
        </Container>
        {isSideMenuOpen && <SideMenu onClickClose={onClickCloseSideMenu} />}
      </Wrapper>
      {isSearchOpen && (
        <>
          <Background onClick={() => setIsSearchOpen(false)} />
          <MobileSearchHeader
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setIsSearchOpen(!isSearchOpen);
                history.push(`/search/${searchValue}`);
                window.location.reload();
              }
            }}
            onClick={() => setIsSearchOpen(false)}
          />
        </>
      )}
    </>
  );
};

const Wrapper = styled.div<IHeader>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #59595926;
  width: 100%;
  background-color: white;
  z-index: 9999;

  html[color-mode='dark'] & {
    border-bottom: solid 1px #595959;
    background-color: #161920;
  }

  ${({ position }) =>
    position &&
    css`
      position: ${position};
    `}
`;

const Container = styled.div`
  display: flex;
  padding: 24px 70px 23px 50px;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 1500px) {
    padding: 27px 0;
    max-width: 1200px;
    margin: 0 auto;
  }

  @media screen and (max-width: 1300px) {
    max-width: unset;
    width: 90%;
  }

  @media screen and (max-width: 960px) {
    padding-top: 26px;
    padding-bottom: 27px;
  }

  @media screen and (max-width: 815px) {
    padding: 20px 0;
  }
`;

const LeftWrap = styled.div`
  display: flex;
  align-items: center;
`;

const LogoLink = styled(Link)`
  height: fit-content;
  margin-right: 68px;

  html[color-mode='dark'] & path {
    fill: white;
  }

  @media screen and (max-width: 1300px) {
    svg {
      width: 131px;
      height: 18px;
      object-fit: contain;
    }
  }

  @media screen and (max-width: 1030px) {
    margin-right: 19px;
  }

  @media screen and (max-width: 815px) {
    margin-right: 0;

    svg {
      width: 121px;
      height: 18px;
    }
  }
`;

const MenuWrap = styled.div`
  display: flex;
  margin-right: 54px;

  @media screen and (max-width: 1300px) {
    margin-right: 0;
  }

  @media screen and (max-width: 815px) {
    display: none;
  }
`;

const RightWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const Menu = ({ to, type }: { to: string; type: string }) => {
  const Icon = require(`../assets/menu-${type}-icon.svg`).default;
  return (
    <MenuContainer exact to={to} activeClassName="active_header_menu">
      <IconWrap>
        <Icon />
      </IconWrap>
      <MenuText>{type}</MenuText>
    </MenuContainer>
  );
};

const MenuContainer = styled(NavLink)`
  display: flex;
  align-items: center;
  margin-right: 50px;
  color: inherit;
  text-decoration: none;

  @media screen and (max-width: 1300px) {
    margin-right: 23px;
  }
`;

const IconWrap = styled.div`
  margin-right: 7px;

  html[color-mode='dark'] & path {
    fill: white;
  }

  .active_header_menu & path {
    fill: #0a62ba !important;
  }

  @media screen and (max-width: 960px) {
    display: none;
  }
`;

const MenuText = styled.span`
  font-size: 15px;
  font-weight: bold;
  line-height: 0.87;
  text-transform: uppercase;
  .active_header_menu & {
    color: #0a62ba;
  }
`;

const Alert = ({ count, ...props }: { count: string; to: string }) => (
  <AlertWrap {...props}>
    <AlertIcon />
    {parseInt(count || '0') > 0 && <AlertCount>{count}</AlertCount>}
  </AlertWrap>
);

const AlertWrap = styled(Link)`
  display: block;
  position: relative;
  margin-right: 30px;

  html[color-mode='dark'] & path {
    fill: white;
  }

  @media screen and (max-width: 1030px) {
    margin-right: 25px;

    svg {
      width: 28px;
      height: 31px;
    }
  }
`;

const AlertCount = styled.span`
  display: flex;
  position: absolute;
  top: -5px;
  right: -5px;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #004081;

  font-size: 13px;
  font-weight: bold;
  line-height: 0.77;
  color: #ffffff;
`;

const SidebarIconWrap = styled.div`
  width: 37px;
  cursor: pointer;

  html[color-mode='dark'] & path {
    fill: white;
  }

  @media screen and (max-width: 1030px) {
    width: 34px;

    svg {
      width: 34px;
      height: 27px;
    }
  }
`;

export default Header;

const Background = styled.div`
  opacity: 0.91;
  background-color: #000000;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
`;

const SearchIconWrap = styled.div`
  margin-right: 21px;
  svg {
    width: 33px;
    height: 33px;
    cursor: pointer;
    path {
      fill: white;
    }
  }
  @media screen and (min-width: 815px) {
    display: none;
  }
`;
