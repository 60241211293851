import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import * as serviceWorker from './serviceWorker';
import App from './client/App';

import './styles/fonts.css';
import './styles/reset.css';
import './styles/index.css';

Sentry.init({
  dsn:
    'https://f1596dee170c43b6b427888e4289c64c@o448573.ingest.sentry.io/5430132',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
