import React from 'react';
import styled from '@emotion/styled';

import useWindowSize from '../utils/useWindowSize';
import Logo from '../assets/logo.svg';

const Footer = () => {
  const { width: screenWidth = 1980 } = useWindowSize();
  const isSmallScreen = screenWidth <= 500;

  return (
    <Container>
      <LogoWrap>
        <Logo />
      </LogoWrap>
      <Info>
        상호 : 에스비(SB)
        {isSmallScreen ? <br /> : ' | '}
        대표자성명 : 한선일
        {isSmallScreen ? <br /> : ' | '}
        주소 : 경기도 의정부시 신흥로 81, 1104호
        <br /> 전자우편주소 : sb@chordscore.com
        {isSmallScreen ? <br /> : ' | '}
        사업자등록번호 : 429-40-00717
        <br /> 통신판매신고번호 : 제 2020-의정부호원-0388 호
      </Info>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  background-color: #dfdfdf;

  html[color-mode='dark'] & {
    background-color: #23262d;
  }

  @media screen and (max-width: 960px) {
    padding: 66px 0;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 58px 30px;
  }
`;

const LogoWrap = styled.div`
  opacity: 0.3;
  margin-right: 70px;

  svg {
    width: 155px;
  }

  html[color-mode='dark'] & path {
    fill: white;
  }

  @media screen and (max-width: 960px) {
    svg {
      width: 121px;
    }
  }

  @media screen and (max-width: 500px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const Info = styled.span`
  opacity: 0.5;
  font-size: 14px;
  line-height: 1.71;
  color: #1e1e1e;
  max-width: 700px;
  word-break: break-word;

  html[color-mode='dark'] & {
    color: white;
  }

  @media screen and (max-width: 960px) {
    font-size: 13px;
    line-height: 1.85;
  }

  @media screen and (max-width: 500px) {
    font-size: 13px;
    line-height: 1.69;
  }
`;

export default Footer;
