import React from 'react';
import styled from '@emotion/styled';

import TitleWithBack from './TitleWithBack';
import {
  SearchBox,
  SearchBoxIcon,
  SearchBoxIconWrap,
  SearchBoxInput,
} from './Search';

interface IMobileSearchHeader {
  value: string;
  onChange: (e: any) => void;
  onKeyPress: (e: any) => void;
  onClick?: () => void;
}

const MobileSearchHeader: React.FC<IMobileSearchHeader> = ({
  value,
  onChange,
  onKeyPress,
  onClick,
}) => {
  return (
    <SearchContainer>
      <TitleWithBack onClick={onClick}>검색</TitleWithBack>
      <SearchBoxWrapper>
        <SearchBox>
          <SearchBoxIconWrap>
            <SearchBoxIcon />
          </SearchBoxIconWrap>
          <SearchBoxInput
            placeholder="검색어를 입력하세요."
            value={value}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
        </SearchBox>
      </SearchBoxWrapper>
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  width: 100%;
  background-color: #151921;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  padding: 10px 20px;
  padding-top: 18px;
`;

const SearchBoxWrapper = styled.div`
  width: 88vw;
  height: 40px;

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 6px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export default MobileSearchHeader;
