import React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import {
  Root,
  Login,
  Register,
  FindPassword,
  Password,
  Setting,
  PolicyPrivacy,
  PolicyService,
  SupportSubmit,
  SupportPost,
  Notice,
  Chart,
  Search,
  Membership,
  Playlist,
  PlaylistInformation,
  Notes,
  KakaoLogin,
  Logout,
  AuthError,
  Profile,
  Recent,
  Main,
} from '../pages';
import Support from '../pages/Support';
import PrivateRoute from '../components/PrivateRoute';
import SNSLogin from '../pages/SNSLogin';
import { SWRConfig } from 'swr';
import { api } from '../utils/api';

const App: React.FC = () => {
  const swrConfig = {
    fetcher(url: string, params: object) {
      return api.get(url, { params }).then((res) => {
        return res.data?.data || res.data;
      });
    },
  };
  return (
    <SWRConfig value={swrConfig}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/old-main" component={Root} />
          <Route exact path="/login" component={SNSLogin} />
          <Route exact path="/chart" component={Chart} />
          <Route exact path="/recent" component={Recent} />

          <Route exact path="/auth/login" component={Login} />
          <Route exact path="/auth/logout" component={Logout} />
          <Route exact path="/auth/register" component={Register} />
          <Route exact path="/auth/find-password" component={FindPassword} />
          <Route exact path="/auth/password" component={Password} />
          <Route exact path="/auth/profile" component={Profile} />
          <Route exact path="/auth/kakao" component={KakaoLogin} />
          <Route exact path="/auth/error" component={AuthError} />

          <PrivateRoute exact path="/support" component={Support} />
          <PrivateRoute
            exact
            path="/support/submit"
            component={SupportSubmit}
          />
          <PrivateRoute
            exact
            path="/support/:postIdx"
            component={SupportPost}
          />

          <PrivateRoute exact path="/membership" component={Membership} />

          <Route exact path="/search/:searchParams" component={Search} />
          <Route exact path="/search/:searchParams" component={Search} />

          <Route exact path="/policy/service" component={PolicyService} />
          <Route exact path="/policy/privacy" component={PolicyPrivacy} />

          <PrivateRoute exact path="/notice" component={Notice} />
          <PrivateRoute exact path="/setting" component={Setting} />

          <PrivateRoute exact path="/playlist" component={Playlist} />
          <PrivateRoute
            exact
            path="/playlist/:playlistIdx"
            component={PlaylistInformation}
          />

          <PrivateRoute exact path="/notes/:songIdx" component={Notes} />
        </Switch>
      </BrowserRouter>
    </SWRConfig>
  );
};

export default App;
