import React from 'react';

import Profile from '../../assets/icons/profile.svg';
import ProfileDark from '../../assets/icons/profile-dark.svg';
import Ticket from '../../assets/icons/ticket.svg';
import Notes from '../../assets/icons/notes.svg';
import NotesDark from '../../assets/icons/notes-dark.svg';
import Support from '../../assets/icons/support.svg';
import SupportDark from '../../assets/icons/support-dark.svg';
import Settings from '../../assets/icons/settings.svg';
import SettingsDark from '../../assets/icons/settings-dark.svg';

export interface IMenuText {
  selected?: boolean;
}

export interface IMenuItem extends IMenuText {
  to?: string;
  icon: React.ReactNode;
  darkIcon?: React.ReactNode;
  menu: string;
  auth?: boolean;
  isDarkMode?: boolean;
  isUploaderOnly?: boolean;
}

export const menuList: IMenuItem[] = [
  {
    to: '/auth/profile',
    icon: <Profile />,
    darkIcon: <ProfileDark />,
    menu: '프로필 수정',
  },
  {
    to: '/membership',
    icon: <Ticket />,
    menu: '이용권',
    selected: true,
  },
  {
    to: '/',
    icon: <Notes />,
    darkIcon: <NotesDark />,
    menu: '악보등록',
    isUploaderOnly: true,
  },
  {
    to: '/support',
    icon: <Support />,
    darkIcon: <SupportDark />,
    menu: '문의/요청사항',
  },
  {
    to: '/setting',
    icon: <Settings />,
    darkIcon: <SettingsDark />,
    menu: '설정',
  },
];
