import styled from '@emotion/styled';
import css from '@emotion/css';

interface IButton {
  styleType?: string;
}

const Button = styled.button<IButton>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 21px 0;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.27;
  border-radius: 7px;
  border: 2px solid transparent;
  outline: 0;
  cursor: pointer;

  @media screen and (max-width: 500px) {
    padding: 16px 0;
  }

  ${({ styleType }) =>
    styleType === 'blue' &&
    css`
      background-color: #295396;
      color: white;
    `}
  ${({ styleType }) =>
    styleType === 'disabled' &&
    css`
      background-color: #8f8f8f;
      color: white;
    `}

  ${({ styleType }) =>
    styleType === 'outline' &&
    css`
      background-color: transparent;
      border-color: #295396;
      color: #295396;
      html[color-mode='dark'] & {
        color: white;
      }
    `}
    ${({ styleType }) =>
      styleType === 'kakao' &&
      css`
        background-color: #f9e816;
        html[color-mode='dark'] & {
          color: #f9e816;
          background: transparent;
          border: solid 1px #f9e816;
        }
      `}
`;

export default Button;
